/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Text, Subtitle, SeparateLine, SeparateLineWrap, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1kpiv6o --full --parallax" name={"introduction"} parallax={true} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--shadow4 --center mb--16 mt--16 pb--40 pl--0 pr--0 pt--0" style={{"maxWidth":500,"backgroundColor":"var(--color-dominant)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/94/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/94/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/94/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/94/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/94/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/94/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box fs--36 mt--30" content={"<span style=\"font-style: italic;\"><span style=\"color: var(--color-variable-1)\">Anna & Thomas</span> </span>"}>
              </Title>

              <Text className="text-box mt--12" content={"We're very grateful you chose to spend the day with us! You can leave us a message if you wish."}>
              </Text>

              <Title className="title-box fs--72 w--900 swpf--uppercase mt--08" content={"<span style=\"color: rgb(0, 0, 0); font-style: italic;\">WEDDING</span>"}>
              </Title>

              <Text className="text-box mt--12" content={"Taking place on 8/4/2018"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"about-us"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/94/crop-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":350}} srcSet={"https://cdn.swbpg.com/t/i/template/94/crop-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/94/crop-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/94/crop-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/94/crop-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/94/crop-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box" content={"Anna Lastname"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":380}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/94/crop-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":350}} srcSet={"https://cdn.swbpg.com/t/i/template/94/crop-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/94/crop-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/94/crop-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/94/crop-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/94/crop-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box" content={"Tom Lastname"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":380}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1kpiv6o --parallax pb--80 pt--80" name={"program"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/94/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--10 pt--10" columns={"3"}>
            
            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box" content={"Program"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box" content={"15:00"}>
              </Title>

              <Text className="text-box mt--10" content={"Click here and start writing. This is an area for your text."}>
              </Text>

              <Title className="title-box" content={"16:00"}>
              </Title>

              <Text className="text-box mt--10" content={"Click here and start writing. This is an area for your text."}>
              </Text>

              <Title className="title-box" content={"17:00"}>
              </Title>

              <Text className="text-box mt--10" content={"Click here and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--shadow4 pb--30 pl--40 pr--40 pt--30" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box" content={"18:00"}>
              </Title>

              <Text className="text-box mt--10" content={"Click here and start writing. This is an area for your text."}>
              </Text>

              <Title className="title-box" content={"19:00"}>
              </Title>

              <Text className="text-box mt--10" content={"Click here and start writing. This is an area for your text."}>
              </Text>

              <Title className="title-box" content={"20:00"}>
              </Title>

              <Text className="text-box mt--10" content={"Click here and start writing. This is an area for your text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"info"} style={{"paddingBottom":null,"backgroundColor":"rgba(255,255,255,1)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center pb--20 pt--20" style={{"paddingTop":null,"paddingBottom":null}}>
              
              <Title className="title-box" style={{"maxWidth":340}} content={"You can look forward to"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--3 pb--40 pt--30 flex--stretch" columns={"3"}>
            
            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--25" style={{"paddingBottom":null,"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/b6695e0a626a44bb9c3fa639f8535105.svg"} svg={false} style={{"maxWidth":128}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Guest arrival"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--30" style={{"paddingBottom":null,"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/aebd6fb2601543a28d89f03da3bcdd90.svg"} svg={false} style={{"maxWidth":128}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Cake cutting"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--30" style={{"paddingBottom":null,"backgroundColor":"rgba(247, 247, 247, 1)"}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/svatebni/d76e5956fada40f0a9ede4ec0c3db013.svg"} svg={false} style={{"maxWidth":128}} srcSet={null} svgColor={"rgba(0,0,0,1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"First dance"}>
              </Subtitle>

              <Text className="text-box text-box--center" style={{"maxWidth":222}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"divider"} style={{"paddingBottom":null}} layout={"l4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"form"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Leave a message"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":536}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 fs--20 w--300" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"footer"} style={{"marginTop":null,"paddingTop":null}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box fs--16" style={{"paddingTop":null}} content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}